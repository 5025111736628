export const SIGN_IN = '/sign-in'
export const RESET_PASSWORD = '/reset-password'
export const NEW_PASSWORD = '/new-password'
export const CHANGE_PASSWORD = '/change-password'
export const DASHBOARD = '/dashboard'
export const ADMIN = '/admin'
export const SIGNATURE = '/purpose-signature'

export const V2 = '/v2'
export const V2_DASHBOARD = '/dashboard'
export const V2_SIGN_IN = '/sign-in'
