import { Skeleton } from '@mui/material'
import * as ROUTES from 'Config/routes'
import React, { Suspense } from 'react'
import { Navigate, Route, Routes, useLocation } from 'react-router'

// const AdminPage = React.lazy(() => import('./pages/admin'))
const DashboardPage = React.lazy(() => import('./pages'))

export const DashboardRoutes = () => {
  const location = useLocation()

  return (
    <Suspense
      fallback={
        <div>
          <Skeleton className="mt-16" variant="rectangular" height={40} />
          <Skeleton className="mt-16" variant="rectangular" height={40} />
          <Skeleton className="mt-16" variant="rectangular" height={40} />
        </div>
      }
    >
      <Routes location={location}>
        <Route
          index
          element={
            <Navigate to={`.${ROUTES.SIGNATURE}${location.search}`} replace />
          }
        />
        <Route path={`${ROUTES.SIGNATURE}/*`} element={<DashboardPage />} />
      </Routes>
    </Suspense>
  )
}

export default DashboardRoutes
