import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined'
import DonutLargeOutlinedIcon from '@mui/icons-material/DonutLargeOutlined'
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined'
import { Box, useTheme } from '@mui/material'
import HeaderSearch from './HeaderSearch'
import { TimeSeriesFilters } from './time-series-filters'
import UserAvatar from './UserAvatar'

const HeaderMenu = () => {
  const theme = useTheme()

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          flex: 5,
        }}
      >
        <TimeSeriesFilters />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            margin: '0px 15px',
            alignItems: 'center',
          }}
        >
          <HeaderSearch />
          <NotificationsOutlinedIcon
            sx={{
              color: theme.color.white,
              margin: '12px',
            }}
          />
          <DonutLargeOutlinedIcon
            sx={{ color: theme.color.white, margin: '12px' }}
          />
          <BookmarkBorderOutlinedIcon
            sx={{ color: theme.color.white, margin: '12px' }}
          />
          <UserAvatar />
        </Box>
      </Box>
    </>
  )
}

export default HeaderMenu
