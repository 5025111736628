import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  alpha,
  Box,
  Button,
  createTheme,
  Popover,
  SxProps,
  Theme,
  ThemeProvider,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import {
  PickersShortcutsItem,
  PickersShortcutsItemContext,
  PickersShortcutsProps,
} from '@mui/x-date-pickers'
import { StaticDateRangePicker } from '@mui/x-date-pickers-pro'
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers-pro/LocalizationProvider'
import { DateRange } from '@mui/x-date-pickers-pro/models'
import dayjs, { Dayjs } from 'dayjs'
import quarterOfTheYear from 'dayjs/plugin/quarterOfYear'
import { Maybe } from 'graphql/jsutils/Maybe'
import { useState } from 'react'
import { useGoogleAnalytics } from '../../../../../../../hooks/useGoogleAnalytics'

dayjs.extend(quarterOfTheYear)

const useStyles = () => {
  const theme = useTheme()
  return {
    buttons: {
      display: 'flex',
      backgroundColor: alpha(theme.palette.background.paper, 0.4),
      alignItems: 'center',
      borderRadius: '4px',
    },
  } satisfies Record<string, SxProps<Theme>>
}

type Props = {
  startDate: Maybe<Dayjs>
  endDate: Maybe<Dayjs>
  onDateRangeChange: (startDate: Maybe<Dayjs>, endDate: Maybe<Dayjs>) => void
}

type DateRangeShortcut = PickersShortcutsItem<DateRange<Dayjs>> & {
  active?: boolean
}

function CustomRangeShortcuts(
  props: Omit<PickersShortcutsProps<DateRange<Dayjs>>, 'items'> & {
    items?: DateRangeShortcut[]
  },
) {
  const { items, onChange, isValid, changeImportance = 'accept' } = props

  if (items == null || items.length === 0) {
    return null
  }

  const resolvedItems = items.map(item => {
    const newValue = item.getValue({ isValid })

    return {
      id: item.id,
      active: item.active,
      label: item.label,
      onClick: () => {
        onChange(newValue, changeImportance, item)
      },
      disabled: !isValid(newValue),
    }
  })

  return (
    <Box
      sx={{
        gridRow: 2,
        gridColumn: 3,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingLeft: theme => theme.spacing(2),
      }}
    >
      <Box
        sx={{
          padding: '12px 16px 4px 16px',
        }}
      >
        <Typography
          variant="subtitle1"
          sx={{
            paddingTop: '8px',
          }}
        >
          Presets
        </Typography>
      </Box>
      <Box
        sx={theme => ({
          display: 'grid',
          gridTemplateColumns: 'repeat(2, auto)',
          gap: theme.spacing(2),
          padding: '8px',
        })}
      >
        {resolvedItems.map(item => {
          return (
            <Box key={item.id}>
              <Button
                fullWidth
                color={item.active ? 'secondary' : 'inherit'}
                size="small"
                disableElevation
                variant={item.active ? 'contained' : 'outlined'}
                disabled={item.disabled}
                onClick={item.onClick}
              >
                {item.label}
              </Button>
            </Box>
          )
        })}
      </Box>
    </Box>
  )
}

export const DateRangeCalendar = (props: Props) => {
  const theme = useTheme()
  const downXl = useMediaQuery(theme.breakpoints.down('xl'))

  const customEvent = useGoogleAnalytics()
  const [value, setValue] = useState<DateRange<Dayjs>>([
    props.startDate ?? null,
    props.endDate ?? null,
  ])
  const [lastShortcut, setLastShortcut] =
    useState<PickersShortcutsItemContext>()
  const [startDate, endDate] = value
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  const shortcutsItems: DateRangeShortcut[] = [
    {
      label: 'Last Week',
      id: 'last_week',
      active: lastShortcut?.id === 'last_week',
      getValue: () => {
        const today = dayjs()
        const prevWeek = today.subtract(7, 'day')
        return [prevWeek.startOf('week'), prevWeek.endOf('week')]
      },
    },
    {
      label: 'This Week',
      id: 'this_week',
      active: lastShortcut?.id === 'this_week',
      getValue: () => {
        const today = dayjs()
        return [today.startOf('week'), today.endOf('week')]
      },
    },
    {
      label: 'Last Month',
      id: 'last_month',
      active: lastShortcut?.id === 'last_month',
      getValue: () => {
        const today = dayjs()
        const prevMonth = today.subtract(1, 'month')
        return [prevMonth.startOf('month'), prevMonth.endOf('month')]
      },
    },
    {
      label: 'This Month',
      id: 'this_month',
      active: lastShortcut?.id === 'this_month',
      getValue: () => {
        const today = dayjs()
        return [today.startOf('month'), today.endOf('month')]
      },
    },
    {
      label: 'Last Quarter',
      id: 'last_quarter',
      active: lastShortcut?.id === 'last_quarter',
      getValue: () => {
        const today = dayjs()
        const prevQuarter = today.subtract(1, 'quarter')
        return [prevQuarter.startOf('quarter'), prevQuarter.endOf('quarter')]
      },
    },
    {
      label: 'This Quarter',
      id: 'this_quarter',
      active: lastShortcut?.id === 'this_quarter',
      getValue: () => {
        const today = dayjs()
        return [today.startOf('quarter'), today.endOf('quarter')]
      },
    },
    {
      label: 'Last 6 Months',
      id: 'last_6_months',
      active: lastShortcut?.id === 'last_6_months',
      getValue: () => {
        const today = dayjs()
        const prev6Months = today.subtract(6, 'month')
        return [prev6Months.startOf('month'), today.endOf('month')]
      },
    },
    {
      label: 'Reset',
      id: 'reset',
      getValue: () => [props.startDate ?? null, props.endDate ?? null],
    },
  ]

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
    customEvent('open_calendar')
  }

  const handleClose = () => {
    setAnchorEl(null)
    customEvent('close_calendar')
    props.onDateRangeChange(startDate, endDate)
  }

  const displayDataFormat = downXl ? 'M.D' : 'MMM D'

  const innerTheme = createTheme({
    palette: {
      secondary: {
        main: '#1BB9B0',
      },
    },
    typography: {
      fontFamily: 'Bebas Neue',
    },
  })

  return (
    <ThemeProvider theme={innerTheme}>
      <Box>
        <Box sx={classes.buttons}>
          <Box>
            <Button
              aria-controls={open ? 'menu-list-grow' : undefined}
              aria-haspopup="true"
              endIcon={
                <ExpandMoreIcon
                  sx={{ opacity: '40%', color: theme.color.white }}
                />
              }
              onClick={handleClick}
              sx={{
                height: '60px',
                color: theme.color.white,
                opacity: '0.4',
              }}
            >
              <Typography
                sx={{ fontWeight: 300, fontFamily: '"Source Sans 3"' }}
              >
                {startDate?.format(displayDataFormat)} &mdash;{' '}
                {endDate?.format(displayDataFormat)}
              </Typography>
            </Button>
          </Box>
        </Box>

        <Popover
          elevation={1}
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <StaticDateRangePicker
              calendars={2}
              currentMonthCalendarPosition={1}
              disableFuture
              value={value}
              onChange={(newValue, context) => {
                setValue(newValue)
                setLastShortcut(context.shortcut)
              }}
              slots={{
                shortcuts: CustomRangeShortcuts,
              }}
              slotProps={{
                shortcuts: {
                  items: shortcutsItems,
                },
                toolbar: {
                  hidden: true,
                },
                actionBar: {
                  hidden: true,
                  actions: [],
                },
              }}
              sx={{
                '&.MuiPickersLayout-root': {
                  background: theme.color.popupGradient,
                  padding: theme.spacing(2),
                },
                '& .MuiDateRangePickerDay-dayInsideRangeInterval': {
                  background: '#73CBEE',
                },
                '& .MuiDateRangePickerDay-day.Mui-selected': {
                  background: '#1BB9B0',
                },
                '& .MuiDateRangeCalendar-monthContainer': {
                  textTransform: 'uppercase',
                },
              }}
              displayWeekNumber
              fixedWeekNumber={6}
            />
          </LocalizationProvider>
        </Popover>
      </Box>
    </ThemeProvider>
  )
}
