import * as ROUTES from 'Config/routes'
import React, { Suspense } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import Backdrop from './Components/backdrop'
import { useFeatureFlags } from './hooks/useFeatureFlags'
import ReputationReport from './pages/reputation-report'
import V2Routes from './pages/v2'

const Dashboard = React.lazy(() => import('./pages/dashboard'))
const ChangePassword = React.lazy(
  () => import('./pages/registration/change-password'),
)
const NewPassword = React.lazy(
  () => import('./pages/registration/new-password'),
)
const ResetPassword = React.lazy(
  () => import('./pages/registration/reset-password'),
)
const SignIn = React.lazy(() => import('./pages/registration/sign-in'))

export const AppRoutes = () => {
  const [enableV2] = useFeatureFlags('feat_darwin-v2')
  return (
    <Suspense fallback={<Backdrop />}>
      <Routes>
        <Route
          index
          element={
            enableV2 ? (
              <Navigate to={ROUTES.V2 + ROUTES.V2_SIGN_IN} replace />
            ) : (
              <SignIn />
            )
          }
        />
        <Route path={ROUTES.SIGN_IN} element={<SignIn />} />
        <Route path={ROUTES.RESET_PASSWORD} element={<ResetPassword />} />
        <Route
          path={`${ROUTES.NEW_PASSWORD}/:userId/:token`}
          element={<NewPassword />}
        />
        <Route path={ROUTES.CHANGE_PASSWORD} element={<ChangePassword />} />
        <Route path={`${ROUTES.DASHBOARD}/*`} element={<Dashboard />} />
        <Route path={`${ROUTES.V2}/*`} element={<V2Routes />} />
        <Route
          path="/reputation-report/:clientId/:industryId"
          element={<ReputationReport />}
        />
      </Routes>
    </Suspense>
  )
}
