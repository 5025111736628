import { Box, Button, Typography } from '@mui/material'
import darwin from 'Assets/DarwinLogoGlyph.png'
import Darwin from 'Assets/darwinV2Name.svg'
import MaskGroup from 'Assets/MaskGroup.webp'

const LeftSide = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        height: '100vh',
        justifyContent: 'space-between',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <img src={darwin} alt="darwin" height="60px" />
        <img src={Darwin} alt="MAHA Global" />
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography variant="h4" align="center">
          Evolve and build trust faster than your competition.
        </Typography>
        <Typography variant="body1" align="center" sx={{ marginTop: '30px' }}>
          The world’s first reputation intelligence platform that knows the next
          best step to navigate opportunities and risks.
        </Typography>
        <Button
          variant="contained"
          color="secondary"
          sx={{ borderRadius: '8px', width: '185px', marginTop: '30px' }}
        >
          Try For Free
        </Button>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        {' '}
        <Typography variant="body2" align="center" sx={{ marginTop: '30px' }}>
          Trusted by the the world's most notable companies
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            borderRadius: '8px',
            marginTop: '10px',
            alignItems: 'center',
          }}
        >
          <img
            src={MaskGroup}
            alt="Accenture, pfizer, salesforce, medtronic, bcg, kroger, levi's"
            style={{ width: '90%', borderRadius: '8px', height: '120px' }}
          />
        </Box>
      </Box>
    </Box>
  )
}
export default LeftSide
