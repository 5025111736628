import {
  alpha,
  Box,
  Divider,
  Drawer,
  List,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'

import { DRAWER_WIDTH } from '../../../../../Common/constants'
import { NavigationItem } from './components/NavigationItem'

type Props = {
  open: boolean
  handleClose: () => void
}

export const Navigation = (props: Props) => {
  const theme = useTheme()
  const downMd = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <Drawer
      elevation={16}
      variant={'permanent'}
      open={props.open}
      onClose={props.handleClose}
      sx={{
        width: downMd ? '150px' : DRAWER_WIDTH,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: {
          width: downMd ? '150px' : DRAWER_WIDTH,
          boxSizing: 'border-box',
          background: theme.color.backgroundGradient,
          color: theme.color.white,
          borderRight: 'none',
        },
      }}
      ModalProps={{
        keepMounted: true,
      }}
    >
      <Toolbar sx={{ height: '130px' }} />
      <Box
        sx={{
          overflow: 'auto',
          scrollbarWidth: 'thin',
          scrollbarColor: `${theme.color.divider1} ${theme.color.divider2}`,
          display: 'flex',
          flexDirection: 'column',
          height: '100vh',
          marginBottom: '80px',
        }}
      >
        <List
          sx={{
            width: '100%',
            maxWidth: DRAWER_WIDTH,
            bgcolor: 'inherit',
            color: 'inherit',
          }}
          component="nav"
        >
          <NavigationItem
            to="/v2/dashboard/purpose-signature/home"
            label="Home"
            height="90px"
          />

          <Divider
            sx={{
              border: '1px solid transparent',
              borderImageSource: `linear-gradient(90deg, ${theme.color.divider1} 0%, ${theme.color.divider2} 100%)`,
              borderImageSlice: 1,
            }}
          />
          <Box
            sx={{
              margin: '25px 0px',
            }}
          >
            <Box
              sx={{
                height: '50px',
                color: theme.color.white,
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Typography
                sx={{
                  paddingLeft: downMd ? '10px' : '80px',
                }}
                variant="body2"
                color={alpha(theme.color.white, 0.5)}
              >
                Pillar
              </Typography>
            </Box>

            <NavigationItem
              to="/v2/dashboard/purpose-signature/community"
              label="Community"
            />
            <NavigationItem
              to="/v2/dashboard/purpose-signature/customers"
              label="CUSTOMERS"
            />
            <NavigationItem
              to="/v2/dashboard/purpose-signature/dei"
              label="DEI"
            />
            <NavigationItem
              to="/v2/dashboard/purpose-signature/employees"
              label="Employees"
            />
            <NavigationItem
              to="/v2/dashboard/purpose-signature/environment"
              label="Environment"
            />
            <NavigationItem
              to="/v2/dashboard/purpose-signature/financials"
              label="Financials"
            />
            <NavigationItem
              to="/v2/dashboard/purpose-signature/governance"
              label="Governance"
            />
          </Box>

          <Divider
            sx={{
              border: '1px solid transparent',
              borderImageSource: `linear-gradient(90deg, ${theme.color.divider1} 0%, ${theme.color.divider2} 100%)`,
              borderImageSlice: 1,
            }}
          />
          <Box
            sx={{
              marginTop: '25px',
            }}
          >
            <Box
              sx={{
                height: '50px',
                color: theme.color.white,
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Typography
                sx={{
                  paddingLeft: downMd ? '10px' : '80px',
                }}
                variant="body2"
                color={alpha(theme.color.white, 0.5)}
              >
                Research
              </Typography>
            </Box>
            <NavigationItem
              to="/v2/dashboard/purpose-signature/reputation"
              label="Reputation"
            />
            <NavigationItem
              to="/v2/dashboard/purpose-signature/sentiment"
              label="Sentiment"
            />
            <NavigationItem
              to="/v2/dashboard/purpose-signature/behavior"
              label="Behavior"
            />
            <NavigationItem
              to="/v2/dashboard/purpose-signature/relevance"
              label="Relevance"
            />
            <NavigationItem
              to="/v2/dashboard/purpose-signature/media-monitoring"
              label="Media Monitoring"
            />
          </Box>
        </List>
      </Box>
    </Drawer>
  )
}
