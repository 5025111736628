import { useQuery } from '@apollo/client'
import { Circle, KeyboardArrowDown } from '@mui/icons-material'
import {
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  Tooltip,
  useTheme,
} from '@mui/material'
import Button from '@mui/material/Button'
import Popover from '@mui/material/Popover'
import Typography from '@mui/material/Typography'
import * as React from 'react'
import { useContext, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { ADMIN_ROLE, COMPANY_VIEW_ROLE } from '../../../../../Common/constants'
import { ClientContext, UserInfoContext } from '../../../../../Context'
import { GET_CLIENTS } from '../../../../../Graphql/query'
import type { Client } from '../../../../../__generated__/graphql'
import { useGoogleAnalytics } from '../../../../../hooks/useGoogleAnalytics'

export const ClientDropdown = () => {
  const theme = useTheme()
  const [searchParams, setSearchParams] = useSearchParams()
  const { userInfo } = useContext(UserInfoContext)
  const { client, setClient } = useContext(ClientContext)
  const customEvent = useGoogleAnalytics()

  const [clients, setClients] = useState<Client[]>([])

  const clientCode = searchParams.get('client')

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  const isLimitedAccess = !(
    userInfo?.role === ADMIN_ROLE || userInfo?.role === COMPANY_VIEW_ROLE
  )
  useQuery(GET_CLIENTS, {
    skip: isLimitedAccess,
    onCompleted: data => {
      const client = clientCode
        ? data.clients.find(c => c.code === clientCode)
        : data.clients.at(0)
      setClients(data.clients)
      setClient?.({
        clientId: client?.id,
        clientCode: client?.code,
        clientName: client?.name,
      })
    },
  })

  const onClientChange = (client?: Client) => {
    setClient?.({
      clientId: client?.id,
      clientCode: client?.code,
      clientName: client?.name,
    })

    customEvent('change_client', {
      selected_client: client?.code,
    })

    if (client?.code) {
      setSearchParams({ client: client.code })
    }
  }

  if (!clients.length) return null

  const defaultClient = clientCode
    ? clients.find(c => c.code === clientCode)
    : clients.at(0)

  return (
    <Box>
      <Tooltip title={client?.clientName ?? ''} placement="top-start">
        <Button
          disableElevation
          disableFocusRipple
          disableRipple
          disabled={isLimitedAccess}
          endIcon={<KeyboardArrowDown />}
          aria-describedby={id}
          variant="text"
          onClick={handleClick}
        >
          <Typography
            variant="h5"
            sx={{ textTransform: 'capitalize', fontWeight: 300 }}
          >
            {client?.clientName?.toLocaleLowerCase() ?? ''}
          </Typography>
        </Button>
      </Tooltip>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        sx={{
          '& .MuiPopover-paper': {
            background: theme.color.popupGradient,
            color: theme.color.black,
            boxShadow: 'none',
            px: theme.spacing(2),
            py: theme.spacing(1),
          },
        }}
      >
        <RadioGroup
          name="radio-buttons-group"
          value={defaultClient?.id}
          onChange={event => {
            const selectedClient = clients.find(
              client => client.id === event.target.value,
            )
            onClientChange(selectedClient)
          }}
        >
          {clients.map(client => (
            <FormControlLabel
              key={client.id}
              value={client.id}
              control={
                <Radio
                  icon={<Circle color="inherit" />}
                  checkedIcon={<Circle />}
                  sx={{
                    '& .MuiSvgIcon-root': {
                      fontSize: 14,
                    },
                  }}
                />
              }
              label={client.name}
              sx={{
                color: theme.color.black,
                '& .MuiTypography-root': {
                  color: theme.color.black,
                },
                '& .MuiRadio-root': {
                  color: theme.color.white,
                },
                '& .MuiRadio-root.Mui-checked': {
                  color: theme.color.black,
                },
              }}
            />
          ))}
        </RadioGroup>
      </Popover>
    </Box>
  )
}
