import { useMutation } from '@apollo/client'
import {
  Alert,
  AlertColor,
  Avatar,
  Backdrop,
  Box,
  Button,
  Divider,
  IconButton,
  Popover,
  Snackbar,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material'
import * as ROUTES from 'Config/routes'
import { LOGOUT } from 'Graphql/mutation'
import React, { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import uniqolor from 'uniqolor'
import { NavigationContext, UserInfoContext } from '../../../../../../Context'

const UserAvatar = () => {
  const { userInfo } = useContext(UserInfoContext)

  const theme = useTheme()
  const avatarColor = uniqolor(userInfo?.email?.at(0) ?? Math.random()).color

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)
  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleCloseMenu = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const [alert, setAlert] = useState<string | boolean>(false)
  const [severity, setSeverity] = useState<AlertColor>('success')

  const navigate = useNavigate()
  const { moveForward } = useContext(NavigationContext)
  const [logout, { client, loading }] = useMutation(LOGOUT, {
    onCompleted: ({ logout }) => {
      if (logout) {
        client.resetStore()
        moveForward?.(() => {
          navigate({
            pathname: `${ROUTES.V2}${ROUTES.V2_SIGN_IN}`,
          })
        })
      }
    },
    onError: () => {
      setSeverity('error')
      setAlert('Something went wrong. Please, try again later')
    },
  })

  const handleClose = (event: unknown, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }
    setAlert(false)
  }

  return (
    <>
      <Box>
        <Tooltip title={'Open settings'}>
          <IconButton
            aria-owns={open ? 'mouse-over-popover' : undefined}
            aria-haspopup="true"
            onClick={handleOpenMenu}
          >
            <Avatar
              style={{
                background: avatarColor,
                width: theme.spacing(4),
                height: theme.spacing(4),
                border: `2px solid ${theme.palette.grey[300]}`,
                fontSize: '1rem',
              }}
            >
              {userInfo?.email?.at(0)?.toUpperCase()}
            </Avatar>
          </IconButton>
        </Tooltip>
        <Popover
          id={'mouse-over-popover'}
          open={open}
          anchorEl={anchorEl}
          onClose={handleCloseMenu}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <Box
            sx={{
              backgroundColor: theme.palette.background.paper,
              padding: 2,
            }}
          >
            <Box
              sx={{
                paddingY: 1,
              }}
            >
              <Typography variant="overline">Account</Typography>
              <Typography variant="body2">{userInfo?.email}</Typography>
            </Box>
            <Divider />
            <Box marginTop={1}>
              <Button
                variant="text"
                size="small"
                fullWidth
                disableElevation
                disableRipple
                disableTouchRipple
                disableFocusRipple
                onClick={() => logout()}
              >
                Logout
              </Button>
            </Box>
          </Box>
        </Popover>
      </Box>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={!!alert}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity={severity}>
          {alert}
        </Alert>
      </Snackbar>
      <Backdrop open={loading} />
    </>
  )
}

export default UserAvatar
