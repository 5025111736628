import SearchIcon from '@mui/icons-material/Search'
import { InputBase, alpha, styled, useTheme } from '@mui/material'

const Search = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  backgroundColor: alpha(theme.palette.background.paper, 0.4),
  width: '427px',
  height: '60px',
  color: theme.color.white,
  position: 'relative',
  borderRadius: theme.shape.borderRadius,

  '&:hover': {
    backgroundColor: alpha(theme.palette.background.paper, 0.4),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  padding: '0px 20px',
  [theme.breakpoints.down('lg')]: {
    width: '200px',
  },
}))

const SearchIconWrapper = styled('div')(() => ({
  flex: 1,
}))

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  flex: 10,
  color: 'inherit',
  fontSize: '18px',
  fontWeight: 300,
  '& .MuiInputBase-input': {
    fontSize: '18px',
    color: theme.color.white,
    opacity: '0.4',
  },
  '& .MuiInputBase-input::placeholder': {
    color: 'inherit',
    opacity: 1,
    fontSize: '18px',
  },
}))

const HeaderSearch = () => {
  const theme = useTheme()
  return (
    <Search>
      <StyledInputBase
        placeholder="Why is my sentiment score going down ?"
        inputProps={{ 'aria-label': 'search' }}
      />
      <SearchIconWrapper>
        <SearchIcon sx={{ color: theme.color.white, opacity: '0.4' }} />
      </SearchIconWrapper>
    </Search>
  )
}

export default HeaderSearch
